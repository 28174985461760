<template>
  <b-container class="p-4">
    <b-row>
      <b-col class="mb-2 d-flex align-items-center justify-content-between"><h4>Enrollments</h4></b-col>
    </b-row>

    <b-row>
      <b-col class="mb-2">
        <b-button variant="outline-success" @click="download" :disabled="downloadInProgress">
          <b-spinner small v-if="downloadInProgress" />
          <feather v-else type="filter"></feather> Download CSV
        </b-button>

        <b-button class="ml-3" variant="outline-primary" v-b-modal.modal-filters>
          <feather type="filter"></feather> Filter list
        </b-button>
        <b-badge class="ml-3" v-if="showResetFiltersButton" role="button"
                variant="danger" @click="resetFilters()">X reset filters</b-badge>
        <b-badge v-for="(value, key) in appliedFilters" :key="key" class="ml-3">{{key}} = {{value}}</b-badge>
        <b-button variant="outline-secondary" class="float-right" @click="refresh" :disabled="loading">
          <b-spinner small v-if="loading" />
          <feather v-else type="refresh-cw"></feather>
        </b-button>
      </b-col>
      <b-modal id="modal-filters" title="Filter enrollments" size="lg">
        <b-form @submit="applyFilters(true)">
          <b-row>
            <b-col cols="4">
              <b-form-group label="Client" label-for="input-1">
                <b-form-select id="input-1" v-model="filters.client_id" :disabled="loading"
                              :options="filters.data.clients"></b-form-select>
              </b-form-group>
              <b-form-group label="Unit" label-for="input-2">
                <b-form-select id="input-2" v-model="filters.unit" :disabled="loading"
                              :options="filters.data.units"></b-form-select>
              </b-form-group>
              <b-form-group label="Account UUID" label-for="input-3">
                <b-form-input id="input-3" v-model="filters.account_uuid" type="text"></b-form-input>
              </b-form-group>
              <b-form-group label="Program UUID" label-for="input-4">
                <b-form-input id="input-4" v-model="filters.uuid" type="text"></b-form-input>
              </b-form-group>
              <b-form-group label="Business Enrolled" label-for="input-5">
                <b-form-select id="input-5" v-model="filters.business_enrolled" :disabled="loading"
                               :options="filters.data.business_enrolled">
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Name" label-for="input-6">
                <b-form-input id="input-6" v-model="filters.name" type="text"></b-form-input>
              </b-form-group>
              <b-form-group label="Email" label-for="input-7">
                <b-form-input id="input-7" v-model="filters.email" type="text"></b-form-input>
              </b-form-group>
              <b-form-group label="Phone" label-for="input-8">
                <b-form-input id="input-8" v-model="filters.phone" type="text"></b-form-input>
              </b-form-group>
              <b-form-group label="DOB" label-for="input-9">
                <b-form-input id="input-9" placeholder="MM-DD-YYYY" pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}" v-model="filters.dob"
                              type="date"></b-form-input>
              </b-form-group>
              <b-form-group label="Fingerprint" label-for="input-9">
                <b-form-input id="input-9" v-model="filters.fingerprint" type="text"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Current Step" label-for="input-10">
                <b-form-select id="input-10" v-model="filters.step" :disabled="loading"
                               :options="filters.data.steps"></b-form-select>
              </b-form-group>
              <b-form-group label="Required ID Check" label-for="input-11">
                <b-form-select id="input-11" v-model="filters.identity_check" :disabled="loading"
                               :options="filters.data.identity_check">
                </b-form-select>
              </b-form-group>
              <b-form-group label="Exclusion Reason" label-for="input-12">
                <b-form-select id="input-12" v-model="filters.excluded_reason" :disabled="loading"
                               :options="filters.data.excluded_reasons"></b-form-select>
              </b-form-group>
              <b-form-group label="Archived Reason" label-for="input-13">
                <b-form-select id="input-13" v-model="filters.archived" :disabled="loading"
                               :options="filters.data.archived_reasons"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <template #modal-footer>
          <b-button size="sm" variant="success" @click="applyFilters(true)">Filter</b-button>
        </template>
      </b-modal>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-table class="member-list" :fields="tableFields" :items="list.items"
                outlined sticky-header="800px" striped
                :busy="loading" empty-text="No members found">
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>
          <template #cell(uuid)="data">
            <ul class="list-unstyled">
              <li>
                <b>Account: </b>
                <small class="really-small">{{ data.item.account_uuid }} </small>
                <feather type="copy" @click="copyToClipboard(data.item.account_uuid)"></feather>
              </li>
              <li>
                <b>Program: </b>
                <small class="really-small">{{ data.item.uuid }} </small>
                <feather type="copy" @click="copyToClipboard(data.item.uuid)"></feather>
              </li>
              <li style="display:flex;align-items:center">
                <div class="truncate">
                  <b>Fingerprint: </b>
                  <small class="really-small" :title="data.item.fingerprint">{{ data.item.fingerprint }} </small>
                </div>
                <feather type="copy" @click="copyToClipboard(data.item.fingerprint)"></feather>
              </li>
              <li v-if="data.item.archived_at" >
                <b > Archived: </b> {{ data.item.archived_reason }}
              </li>
            </ul>
          </template>
          <template #cell(client_name)="data">
            <ul class="list-unstyled">
              <li><b>Client: </b>
              <router-link v-if="$isSudo" :to="{ name: 'EditClient', params: { clientID: data.item.client_id } }" target="_blank">
                {{ data.item.client_name }}
              </router-link>
              <p v-else>{{ data.item.client_name }}</p>
              </li>
              <li><b>Unit: </b>{{ data.item.unit }}</li>
            </ul>
          </template>
          <template #cell(current_step)="data">
            <ul class="list-unstyled">
              <li><b>Step: </b>{{ data.item.current_step }}</li>
              <li v-if="data.item.business_enrolled_at !== null">
                  <b>Enrolled at: </b>{{ formatDate(data.item.business_enrolled_at, 'hh:mm YYYY/MM/DD') }}
              </li>
              <li v-if="data.item.current_step === 'enrollment_finished'">
                  <b>Screened at: </b>{{ formatDate
                  (data.item.enrollment_finished_at, 'hh:mm YYYY/MM/DD') }}
              </li>
              <li v-if="data.item.excluded_reason !== '-' && data.item.excluded_reason !== ''"><b>Exclusion: </b>{{ data.item.excluded_reason }}</li>
            </ul>
          </template>
          <template #cell(member)="data">
            <ul class="list-unstyled">
              <li><b>Name: </b>{{ data.item.name }}</li>
              <li @click="copyToClipboard(data.item.email)" style="cursor: pointer;"><b>Email: </b>{{ data.item.email }}</li>
              <li><b>Phone: </b>{{ data.item.phone }}</li>
              <li><b>DOB: </b>{{ formatDate(data.item.dob, 'YYYY/MM/DD') }}</li>
            </ul>
          </template>
          <template #cell(step_changed_at)="data">
            <span>{{ formatDate(data.value, 'hh:mm YYYY/MM/DD') }}</span>
          </template>
          <template #cell(last_enrollment_assistant)="data">
            <template v-if="data.value">
              <b-spinner small v-if="!userNames[+data.value]" />
              <span v-else>{{ userNames[+data.value] }}</span>
            </template>
            <span v-else>-</span>
          </template>
          <template #cell(more)="data">
            <div class="d-flex justify-content-center align-items-center">
              <b-button variant="primary" class="ml-0" size="sm"
                        @click="selectItem(data.item.uuid)" v-b-modal.modal-details>More
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="d-flex justify-content-center">
        <b-button variant="primary" @click="firstPage()">
          <feather class="back-icon" type="arrow-left"></feather>
          <feather class="back-icon" type="arrow-left"></feather>
        </b-button>
        <b-button variant="primary" @click="prevPage()">
          <feather class="back-icon" type="arrow-left"></feather>
        </b-button>
        <b-button variant="primary">
          {{ list.page }}
        </b-button>
        <b-button variant="primary" v-if="list.items.length == list.limit" @click="nextPage()">
          <feather class="back-icon" type="arrow-right"></feather>
         </b-button>
      </b-col>
    </b-row>

    <b-modal id="modal-details" size="lg">
      <template #modal-title>
        {{ `${selectedItem.client_name} - ${selectedItem.name}` }}
      </template>
      <b-spinner v-if="loadingDetails" label="Loading details..." />
      <template v-else>
        <div class="d-flex flex-column mb-2">
          <small>Account UUID: {{ selectedItem.account_uuid || '-' }}</small>
          <small>Program UUID: {{ selectedItem.uuid }}</small>
          <small>Fingerprint: {{ selectedItem.fingerprint }}</small>
          <small>Started at: {{ formatDate(selectedItem.created_at, 'hh:mm YYYY/MM/DD') }}</small>
          <div class="d-flex flex-row mt-2">
            <b-overlay :show="impersonationInProgress" rounded opacity="0.6" spinner-small>
              <b-button v-if="canImpersonate" class="mr-1" variant="primary" @click="impersonate">
                Enroll for member
              </b-button>
            </b-overlay>
            <b-overlay :show="passwordResetInProgress" rounded opacity="0.6" spinner-small>
              <b-button v-if="canSendPasswordResetEmail" class="mr-1" variant="primary" @click="sendPasswordResetEmail">
                Send password reset email
              </b-button>
              <router-link class="btn btn-success" v-if="selectedItem.current_step === 'enrollment_finished'"
                :to="`/ops/search-member?account_uuid=${selectedItem.account_uuid}`">
                See account details
              </router-link>
            </b-overlay>
            <b-overlay v-if="canUnblockMember" :show="unblockingInProgress" rounded opacity="0.6" spinner-small>
              <b-button class="mr-1" variant="primary" @click="unblockMember">
                Unblock Member
              </b-button>
            </b-overlay>
            <b-overlay v-if="$isSudo" :show="unblockingInProgress" rounded opacity="0.6" spinner-small>
                <b-button class="mr-1" variant="primary" @click="showArchiveModal()">
                Archive Member
              </b-button>
            </b-overlay>
          </div>
        </div>
        <div class="card mb-4">
          <div class="card-header">
            General Information
          </div>
          <div class="card-body">
            <b-row class="mb-3">
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Onboarding Flow</b>
                  <span>{{ selectedItem.onboarding_flow }}</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Onboarding Type</b>
                  <span>{{ selectedItem.onboarding_type }}</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Therapist</b>
                  <template v-if="selectedItem.owner_therapist_id">
                    <b-spinner small v-if="!therapistNames[+selectedItem.owner_therapist_id]" />
                    <span v-else>{{ therapistNames[+selectedItem.owner_therapist_id] }}</span>
                  </template>
                  <span v-else>-</span>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>RVP Name</b>
                  <span>{{ selectedItem.rvp_name }}</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Service Type</b>
                  <span>{{ selectedItem.service_type }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>ASO Group</b>
                  <span title="Download multi-tenant file to check the ID">
                    {{ selectedItem.aso_group }}
                  </span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Excluded</b>
                  <span v-if="selectedItem.is_excluded">Yes</span>
                  <span v-else>No</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Eligible</b>
                  <span v-if="selectedItem.is_eligible">Yes</span>
                  <span v-else>No</span>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Condition</b>
                  <span>{{ selectedItem.condition }}</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Assigned Kit</b>
                  <span>{{ selectedItem.assigned_kit }}</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Multimode Compatible</b>
                  <span v-if="selectedItem.multimode_compatible">Yes</span>
                  <span v-else>No</span>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Identity Check</b>
                  <b-spinner small
                    v-if="selectedItem.account_uuid !== '-' && !identityCheck[selectedItem.account_uuid]"></b-spinner>
                  <div v-else>
                    <span>{{ getIdentityCheckDescription(selectedItem.account_uuid) }}</span>
                    <div v-if="getIdentityCheckDescription(selectedItem.account_uuid) === 'Pending'">
                      <div class="d-flex flex-column justify-content-center"
                        v-if="this.identityCheck[selectedItem.account_uuid].action">
                        <span>Attempts: {{ identityCheck[selectedItem.account_uuid].action.count }}</span>
                        <span>Last Attempts: {{ identityCheck[selectedItem.account_uuid].action.last_attempts_at }}</span>
                      </div>
                      <b-button variant="primary" @click="confirmIdentity(selectedItem.account_uuid)">Confirm
                        Identity</b-button>
                      <span class="m-lg-1"> </span>
                      <b-button variant="primary"
                        @click="identityConfirmationAttempts(selectedItem.account_uuid)">Confirmation Attempt</b-button>
                    </div>
                    <div class="d-flex flex-column justify-content-center"
                      v-if="getIdentityCheckDescription(selectedItem.account_uuid) === 'Done'">
                      <span> Who:
                        {{ getUserFirstLastName(identityCheck[selectedItem.account_uuid].flags.identityChecked.set_by) }}
                      </span>
                      <span>When: {{ identityCheck[selectedItem.account_uuid].flags.identityChecked.set_at }}</span>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Archived</b>
                  <span>{{ selectedItem.archived_at ? 'Yes': 'No' }}</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Archived Reason</b>
                  <span>{{ selectedItem.archived_reason }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Is SSO</b>
                  <span>{{ selectedItem.external_integration_type ? 'Yes': 'No' }}</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Integration Identifier</b>
                  <span>{{ selectedItem.external_integration_identifier ? selectedItem.external_integration_identifier : '-' }}</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Is high risk</b>
                  <span>{{ selectedItem.metadata?.is_high_risk ? 'Yes' : 'No' }}</span>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card mb-4">
          <div class="card-header">
            Call Information
          </div>
          <div class="card-body">
            <b-row class="mb-3">
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Slot</b>
                  <span> {{
                    selectedItem.flow && selectedItem.flow === 'skip' ? '-' :
                    formatDate(selectedItem.video_call_date, 'YYYY-MM-DD HH:mm:ss z', selectedItem.video_call_timezone)
                  }}
                  </span>
                  <small v-if="selectedItem.video_call_date !== '-' && selectedItem.appointment_uuid">
                    <a target="_blank" :href="rescheduleURL(selectedItem.appointment_uuid, selectedClient)">
                      Reschedule link
                    </a>
                  </small>
                </div>
              </b-col>
              <b-col v-if="selectedItem.appointment_uuid">
                <div class="d-flex flex-column justify-content-center">
                  <b>Emergency coverage</b>
                  <a target="_blank" :href="emergencyCoverageURL(selectedItem.appointment_uuid, selectedClient)">
                    Emergency coverage Link
                  </a>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Channel</b>
                  <span>{{ preferredCallChannel || '-' }}</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Is Finished</b>
                  <span>{{ selectedItem.onboarding_form_submitted ? 'Yes' : 'No' }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Therapist</b>
                  <template v-if="selectedItem.video_call_therapist_id">
                    <b-spinner small v-if="!therapistNames[+selectedItem.video_call_therapist_id]" />
                    <span v-else>{{ therapistNames[+selectedItem.video_call_therapist_id] }}</span>
                  </template>
                  <span v-else>-</span>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card mb-4">
          <div class="card-header">
            Address Information
          </div>
          <div class="card-body">
            <b-row class="mb-3">
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Address1</b>
                  <span>{{ selectedItem.address1 || '-' }}</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Address2</b>
                  <span>{{ selectedItem.address2 || '-' }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Zip Code</b>
                  <span>{{ selectedItem.zip_code || '-' }}</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>City</b>
                  <span>{{ selectedItem.city || '-' }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>State</b>
                  <span>{{ selectedItem.state }}</span>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-column justify-content-center">
                  <b>Country</b>
                  <span>{{ selectedItem.country || '-' }}</span>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card mb-4">
        <div class="card-header">
          Insurance Information
        </div>
        <div class="card-body">
          <b-row class="mb-3">
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Member Type</b>
                <span>{{ selectedItem.coverage_type }}</span>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Subscriber ID</b>
                <span v-if="selectedItem.insurance_number">Yes</span>
                <span v-else>No</span>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Subscriber Name</b>
                <span>{{ selectedItem.subscriber_first_name }} {{ selectedItem.subscriber_last_name }}</span>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Subscriber DOB</b>
                <span>{{ formatDate(selectedItem.subscriber_birthdate, 'MM-DD-YYYY') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Health insurance provider</b>
                <span>{{ selectedItem.payer_name }}</span>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Insurance number</b>
                <span>{{ selectedItem.insurance_number || '-' }}</span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div v-if="$isSudo" class="card mb-4">
        <div class="card-header">
          Metadata
          <feather type="copy" class="float-right" @click="copyToClipboard(JSON.stringify(selectedItem.metadata))"></feather>
        </div>
        <div class="card-body">
          <b-row class="mb-3">
            <b-col>
              <pre> {{ selectedItem.metadata }} </pre>
            </b-col>
          </b-row>
        </div>
       </div>

       <div v-if="$isSudo" class="card mb-4">
        <div class="card-header">
          Dynamic Flow - {{ selectedItem.dynamic_flow_uuid }}
          <feather type="copy" class="float-right" @click="copyToClipboard(JSON.stringify(selectedItem.dynamic_flow))"></feather>
        </div>
        <div class="card-body">
          <b-row class="mb-3">
            <b-col>
              <pre> {{ selectedItem.dynamic_flow }} </pre>
            </b-col>
          </b-row>
        </div>
       </div>
    </template>
    </b-modal>

    <b-modal id="modal-archive" title="Archive" @ok="archiveMemberWithReason(archiveReasonSudo)">
      Are you sure you want to archive?
      This action is NOT revertable!
    </b-modal>
  </b-container>
</template>

<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import moment from 'moment-timezone';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import * as R from 'ramda';
import { D2C_OPTIONS } from '@/scripts/constants';

/* eslint-disable */
export default {
  name: 'ListEnrolledMembers',
  data() {
    return {
      currentPage: 1,
      selectedItem: {},
      loading: true,
      loadingDetails: false,
      filters: {
        data: {
          units: [
            { value: '', text: '' },
            { value: 'sword', text: 'sword' },
            { value: 'bloom', text: 'bloom' },
            { value: 'mind', text: 'mind' },
            { value: 'move', text: 'move' },
            { value: 'dpt_go', text: 'dpt_go' },
          ],
          identity_check: [
            { value: '', text: '' },
            { value: 'pending', text: 'Pending' },
            { value: 'not_required', text: 'Not Required' },
            { value: 'done', text: 'Done' },
          ],
          business_enrolled: [
            { value: null, text: '' },
            { value: 'true', text: 'Yes' },
            { value: 'false', text: 'No' },
          ],
          clients: [],
          steps: [],
          excluded_reasons: [],
          archived_reasons: [],
        },
        unit: '',
        client_id: '',
        step: '',
        name: '',
        email: '',
        phone: '',
        dob: '',
        account_uuid: '',
        fingerprint: '',
        uuid: '',
        identity_check: '',
        excluded_reason: '',
        archived: '',
      },
      appliedFilters: {},
      tableFields: [
        { key: 'uuid', label: 'UUIDs' },
        { key: 'client_name', label: 'Client' },
        { key: 'current_step', label: 'Step' },
        { key: 'member', label: 'Member' },

        { key: 'last_enrollment_assistant', label: 'EA' },
        { key: 'step_changed_at', label: 'Last Update' },
        { key: 'more', label: '' },
      ],
      list: {
        page: 1,
        limit: 50,
        items: [],
        total: 999999,
      },
      therapistNames: {},
      userNames: {},
      payers: [],
      users: {},
      identityCheck: {},
      downloadInProgress: false,
      impersonationInProgress: false,
      enrollmentAssistants: {},
      passwordResetInProgress: false,
      passwordResetsDone: {},
      unblockingInProgress: false,
      archiveReasonSudo: 'manual_sudo',
      selectedClient: {},
    };
  },
  components: {
    InfiniteLoading,
  },
  async beforeMount() {
    // populate filters from query params
    for (const filterName in this.filters) {
      if (filterName === 'data') continue;
      if (!this.$route?.query?.[filterName]) continue;

      this.appliedFilters[filterName] = this.$route.query[filterName];
      this.filters[filterName] = this.$route.query[filterName];
    }

    this.fetchFilters();
    this.fetchEnrollmentsList();
  },
  computed: {
    ...mapGetters({
      rescheduleURL: 'Core/getRescheduleURL',
      emergencyCoverageURL: 'Core/getEmergencyCoverageURL',
      environmentConfigs: 'Core/getEnvironmentConfigs',
      user: 'User/profile',
    }),
    canUnblockMember() {
      return !!(
        this.selectedItem?.account_uuid
        && this.selectedItem.early_onboarded_at
        && this.selectedItem.current_step !== 'enrollment_finished'
        && !this.selectedItem.archived_at
      );
    },
    canImpersonate() {
      // member must have an account and an incomplete onboarding
      return !!this.selectedItem?.account_uuid;
    },
    canSendPasswordResetEmail() {
      const { account_uuid } = this.selectedItem || {};
      return account_uuid && !this.passwordResetsDone[account_uuid];
    },
    preferredCallChannel() {
      return {
        video: 'Video Call',
        phone: 'Phone Call',
      }[this.selectedItem?.flow] ?? '-'
    },
    showResetFiltersButton() {
      for (const key in this.appliedFilters) {
        if (this.appliedFilters[key]) return true;
      }
      return false;
    },
    identityCheckDescription() {
      const identityCheck = this.identityCheck[this.selectedItem?.account_uuid];
      if (!identityCheck?.flags) return 'Not Required';
      if (identityCheck.flags.requiresIdentityCheck && !identityCheck.flags.identityChecked) { return 'Pending'; }
      if (identityCheck.flags.requiresIdentityCheck && identityCheck.flags.identityChecked) { return 'Done'; }
      if (!identityCheck.flags.requiresIdentityCheck) { return 'Not Required'; }

      return 'Unknown';
    }
  },
  watch: {
    'list.page': 'fetchEnrollmentsList',
  },
  methods: {
    firstPage() {
      this.list.page = 1;
    },
    prevPage() {
      this.list.page = this.list.page - 1 || 1;
    },
    nextPage() {
      this.list.page = this.list.page + 1;
    },
    copyToClipboard(value) {
      if (!navigator?.clipboard) {
        this.$noty.error('Browser feature unsupported.');
        return;
      }

      navigator.clipboard
        .writeText(value)
        .then(() => this.$noty.success('Copied successfully.'))
        .catch(e => this.$noty.error(e.message || 'Unknown error.'));
    },
    getIdentityCheckDescription(accountId) {
      const identityCheck = this.identityCheck[accountId];
      if (!identityCheck || !identityCheck.flags) { return 'Not Required'; }
      if (identityCheck.flags.requiresIdentityCheck && !identityCheck.flags.identityChecked) { return 'Pending'; }
      if (identityCheck.flags.requiresIdentityCheck && identityCheck.flags.identityChecked) { return 'Done'; }
      if (!identityCheck.flags.requiresIdentityCheck) { return 'Not Required'; }

      return 'Unknown';
    },
    unblockMember() {
      this.archiveMemberWithReason('manual_early_kit_unblock');
    },
    archiveMemberWithReason(archived_reason) {
      this.unblockingInProgress = true;
      axios.post(`v1/enrollment/members/${this.selectedItem.uuid}/archive`, { archived_reason })
        .then(() => this.$noty.success('Member Archived', { timeout: 750, progressBar: false }))
        .catch(e => {
          this.$noty.error('An unknown error ocurred, please try again');
          console.error('Failed to unblock member: ', e);
        })
        .finally(() => this.unblockingInProgress = false);
    },
    formatDate(date, format, tz) {
      let parsedDate = moment(date);
      if (!parsedDate.isValid()) { return '-'; }
      if (tz) {
        parsedDate = moment.tz(date, tz);
      } else {
        parsedDate.utcOffset(date);
      }
      return parsedDate.format(format);
    },
    fetchFilters() {
      axios.get('v1/onboarding/enrollments/filters')
        .then(response => {
          const data = response?.data ?? {};

          data.clients ||= [];
          this.filters.data.clients = data.clients.map(
            client => ({ value: client.id, rvp_name: client.rvp_name, text: `${client.display_name} (ID: ${client.id})` })
          );
          this.filters.data.clients.unshift({ value: '', text: '' })

          data.steps ||= [];
          this.filters.data.steps = data.steps.map(
            step => ({ text: step, value: step })
          );
          this.filters.data.steps.unshift({ value: '', text: '' })

          data.excluded_reasons ||= [];
          this.filters.data.excluded_reasons = data.excluded_reasons.map(
            reason => ({ text: reason, value: reason })
          );
          this.filters.data.excluded_reasons.unshift(
            { value: '', text: '' },
            { value: '__empty__', text: '(None)' },
          );
          data.archived_reasons ||= [];
          this.filters.data.archived_reasons = data.archived_reasons.map(
            reason => ({ text: reason, value: reason })
          );
          this.filters.data.archived_reasons.unshift(
            { value: '', text: '' },
            { value: 'ignore', text: 'Skip Archived' },
          );
        })
        .catch(e => {
          const msg = 'Failed to fetch available filters'
          this.$noty.error(msg);
          console.error(msg, e)
        })

    },
    fetchEnrollmentsList() {
      this.loading = true;
      const filters = Object.assign({}, this.appliedFilters);
      filters.page = this.list.page -1; // endpoint first page is 0
      filters.limit = this.list.limit;
      const queryParams = new URLSearchParams(filters).toString();

      return axios.get(`v1/onboarding/enrollments?${queryParams}`)
        .then(response => {
          const data = response.data;
          this.list.items = data.items.map(item => {
            const mapped = this.formatAPIResponseItem(item);
            mapped.last_enrollment_assistant && this.requestUserName(mapped.last_enrollment_assistant);
            return mapped;
          });
        })
        .catch(e => {
          this.$noty.error('Error loading enrollments list');
          console.error(e);
        })
        .finally(() => {
          this.loading = false
          this.openModalFromQueryParams(this.list.items)
        });
    },
    refresh() {
      this.fetchEnrollmentsList();
    },
    async fetchEnrollment(uuid) {
      const formatedData = await axios.get(`v1/onboarding/enrollments/${uuid}`)
        .then(({ data }) => this.formatAPIResponseItem(data));


        this.selectedClient = await axios.get(`v1/clients/${formatedData.client_id}`, { params: { by: 'id' } })
        .then(response => response.data.client )
        .catch(e => {
          this.$noty.error('Error loading client');
          console.error('Failed to impersonate: ', err);
        }) 

        return formatedData
    },
    applyFilters(resetPage = false) {
      if (resetPage) this.list.page = 1;

      this.appliedFilters = {};
      for (const filterName in this.filters) {
        if (filterName === 'data') continue;
        const value = this.filters[filterName];
        if (value) this.appliedFilters[filterName] = value;
      };

      this.$bvModal.hide('modal-filters');
      this.fetchEnrollmentsList();
      this.$router.replace({
        ...this.$router?.currentRoute,
        query: { ...this.appliedFilters },
      });
    },
    showArchiveModal() {
      this.$bvModal.show('modal-archive');
    },
    clearFilterChoices() {
      for (const filterName in this.filters) {
        if (filterName === 'data') continue;
        this.filters[filterName] = '';
      }
    },
    resetFilters() {
      this.appliedFilters = {};
      this.clearFilterChoices();
      this.fetchEnrollmentsList();
      this.$router.replace({ ...this.$router?.currentRoute, query: {} })
    },
    formatAPIResponseItem(item) {
      const resultItem = {};
      resultItem.account_uuid = R.path(['enrollment', 'account_uuid'], item);
      resultItem.uuid = R.pathOr('-', ['enrollment', 'uuid'], item);
      resultItem.current_step = R.pathOr('-', ['enrollment', 'current_step'], item);
      resultItem.client_id = R.either(
        R.path(['client_references', 'id']),
        R.path(['enrollment', 'client_id']),
      )(item);
      resultItem.is_excluded = R.path(['enrollment', 'is_excluded'], item);
      resultItem.excluded_reason = R.pathOr('-', ['enrollment', 'excluded_reason'], item);
      resultItem.enrollment_finished_at = R.pathOr('-', ['enrollment', 'enrollment_finished_at'], item);
      resultItem.business_enrolled_at = R.path(['enrollment', 'business_enrolled_at'], item);
      resultItem.is_impersonated = R.pathSatisfies(t => t && t !== 'self', ['enrollment', 'metadata', 'audit', 'type'], item);
      resultItem.last_enrollment_assistant = item.is_impersonated && +item.enrollment?.metadata?.audit?.user_id || undefined;
      resultItem.flow = R.path(['enrollment', 'metadata', 'decisions', 'call_preferences', 'flow'], item);
      resultItem.multimode_compatible = R.path(['enrollment', 'metadata', 'multimode', 'compatible'], item);
      resultItem.unit = R.pathOr('sword', ['enrollment', 'metadata', 'unit'], item);
      resultItem.onboarding_form_submitted = R.path(['enrollment', 'onboarding_form_submitted'], item);
      resultItem.created_at = R.path(['enrollment', 'created_at'], item);
      resultItem.step_changed_at = R.path(['enrollment', 'step_changed_at'], item);
      resultItem.ui_app_version = R.path(['enrollment', 'ui_app_version'], item);
      resultItem.service_type = R.pathOr('-', ['enrollment', 'service_type'], item);
      resultItem.metadata = R.pathOr('{}', ['enrollment', 'metadata'], item);
      resultItem.dynamic_flow_uuid = R.pathOr('', ['enrollment', 'dynamic_flow_uuid'], item);
      resultItem.dynamic_flow = R.pathOr('{}', ['enrollment', 'dynamic_flow'], item);
      resultItem.name = item.insurance_info?.first_name || item.insurance_info?.last_name
        ? `${item.insurance_info.first_name || ''} ${item.insurance_info.last_name || ''}`.trim()
        : undefined;
      resultItem.dob = R.pathOr('-', ['insurance_info', 'date_of_birth'], item);
      resultItem.email = R.pathOr('-', ['insurance_info', 'email'], item);
      resultItem.fingerprint = R.pathOr('-', ['insurance_info', 'fingerprint'], item);
      resultItem.state = R.pathOr('-', ['insurance_info', 'state'], item);
      resultItem.aso_group = R.pathOr('-', ['insurance_info', 'aso_identifier'], item);
      resultItem.is_eligible = R.path(['insurance_info', 'is_eligible'], item);
      resultItem.coverage_type = R.pathOr('-', ['insurance_info', 'relationship'], item);
      resultItem.insurance_number = R.path(['insurance_info', 'insurance_number'], item);
      resultItem.subscriber_first_name = R.pathOr('-', ['insurance_info', 'subscriber_first_name'], item);
      resultItem.subscriber_last_name = R.pathOr('', ['insurance_info', 'subscriber_last_name'], item);
      resultItem.subscriber_birthdate = R.pathOr('-', ['insurance_info', 'subscriber_date_of_birth'], item);
      resultItem.payer_id = R.path(['insurance_info', 'payer_id'], item);
      resultItem.payer_name = '-';
      resultItem.phone = R.pathOr('-', ['insurance_info', 'phone_number'], item);
      resultItem.client = this.filters.data.clients.find(c => c.value === item.client_id) || {};
      resultItem.client_name = R.pathOr('-', ['client_references', 'name'], item);
      resultItem.rvp_name = R.pathOr('-', ['client_references', 'rvp_name'], item);
      resultItem.appointment_uuid = R.path(['schedule', 'appointment_uuid'], item);
      resultItem.video_call_date = R.pathOr('-', ['schedule', 'start'], item);
      resultItem.video_call_timezone = R.pathOr('-', ['schedule', 'selected_timezone'], item);
      resultItem.video_call_therapist_id = R.path(['schedule', 'therapist_id'], item);
      resultItem.owner_therapist_id = R.either(
        R.path(['patient', 'owner_id']),
        R.path(['therapist', 'therapist_id']),
      )(item);
      resultItem.assigned_kit = R.pathOr('-', ['condition', 'kit'], item);
      resultItem.condition = R.pathOr('-', ['condition', 'selected_condition'], item);
      resultItem.address1 = R.path(['insurance_info', 'address1'], item);
      resultItem.address2 = R.path(['insurance_info', 'address2'], item);
      resultItem.zip_code = R.path(['insurance_info', 'zip_code'], item);
      resultItem.country = R.path(['insurance_info', 'country'], item);
      resultItem.city = R.path(['insurance_info', 'city'], item);
      resultItem.onboarding_flow = R.pathOr('-', ['enrollment', 'onboarding_flow'], item);
      resultItem.onboarding_type = R.pathOr('-', ['enrollment', 'onboarding_type'], item);
      resultItem.onboarded_at = R.path(['enrollment', 'onboarded_at'], item);
      resultItem.early_onboarded_at = R.path(['enrollment', 'early_onboarded_at'], item);
      resultItem.archived_at = R.path(['enrollment', 'archived_at'], item);
      resultItem.archived_reason = R.path(['enrollment', 'archived_reason'], item);
      resultItem.external_integration_type = R.path(['account_metadata', 'external_integration', 'type'], item);
      resultItem.external_integration_identifier = R.path(['account_metadata', 'external_integration', 'integration_identifier'], item);
      // resultItem._rowVariant = resultItem.archived_at ? 'danger' : undefined

      return resultItem;
    },
    requestUserName(userRefID) {
      if (this.userNames[+userRefID] !== undefined) return;

      this.$set(this.userNames, +userRefID, '');
      axios.get(`v1/users/${userRefID}`)
        .then(({ data }) => this.userNames[+userRefID] = `${data.user.firstname} ${data.user.lastname} (${userRefID})`)
        .catch(console.error);
    },
    requestTherapistName(therapistID) {
      if (this.therapistNames[+therapistID] !== undefined) return;

      this.$set(this.therapistNames, +therapistID, '');
      axios.get(`v1/therapists/${therapistID}`)
        .then(({ data }) => this.therapistNames[+therapistID] = `${data.user.firstname} ${data.user.lastname} (${therapistID})`)
        .catch(console.error);
    },
    requestClientPayerName(item) {
      const { list } = this.payers.find(c => c.client_id === item.client_id) ?? {};
      if (list) {
        const payer = list.find(p => p.key === item.payer_id);
        if (payer) { item.payer_name = payer.name; }
        return;
      }

      axios.get(`v1/onboarding/eligibility/${item.client_id}/payers?limit=100`)
        .then(({ data }) => {
          const { data: list } = data ?? {}
          if (!list?.length) return;

          this.payers.push({ client_id: item.client_id, list });
          const payer = list.find(p => p.key === item.payer_id);
          if (payer) { item.payer_name = payer.name; }
        })
        .catch(console.error)
    },
    requestUserInfo(userId) {
      axios.get(`v1/users/${userId}`)
        .then(({ data }) => Vue.set(this.users, userId, data.user))
        .catch(console.error);
    },
    requestAccountIdentityCheck(accountId) {
      Vue.delete(this.identityCheck, accountId);

      Promise.all([
        axios.get(`v1/onboarding/members/flags?account_id=${accountId}`),
        axios.get(`v1/onboarding/members/actions/identity_check_attempt_failed?account_id=${accountId}`),
      ])
        .then(([flags, action]) => {
          const data = {
            flags: {
              identityChecked: flags.data.find(flag => flag.slug === 'identity_checked'),
              requiresIdentityCheck: flags.data.find(flag => flag.slug === 'requires_identity_check'),
            },
            action: action.data,
          };

          Vue.set(this.identityCheck, accountId, data);

          if (data.flags.identityChecked) {
            this.requestUserInfo(data.flags.identityChecked.set_by);
          }
        }).catch(console.error);
    },
    selectItem(enrollmentUUID) {
      this.loadingDetails = true;
      this.fetchEnrollment(enrollmentUUID)
        .then(item => {
          item.payer_id && this.requestClientPayerName(item);
          item.account_uuid && this.requestAccountIdentityCheck(item.account_uuid);
          item.owner_therapist_id && this.requestTherapistName(item.owner_therapist_id);
          item.video_call_therapist_id
            && item.video_call_therapist_id != item.owner_therapist_id
            && this.requestTherapistName(item.video_call_therapist_id);

          this.selectedItem = item;
        })
        .catch(e => {
          this.$noty.error('Error loading details');
          console.error(e);
        })
        .finally(() => this.loadingDetails = false);
    },
    openModalFromQueryParams(enrollments) {
      if (
        this.$route?.query?.details === "true"
        && this.$route?.query?.account_uuid
        && enrollments.length === 1
        ) {
        this.$bvModal.show('modal-details')
        this.selectItem(enrollments[0].uuid)
      }
    },
    async confirmIdentity(accountId) {
      const result = await this.$bvModal.msgBoxConfirm(
        'Are you sure you would like to confirm the ID of this member?',
        {
          title: 'Confirm identity check confirmation',
          okTitle: 'Confirm',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        },
      );

      if (!result) {
        return;
      }

      Vue.delete(this.identityCheck, accountId);

      axios.post('v1/onboarding/members/flags/identity-checked', { account_id: accountId })
        .then(() => this.requestAccountIdentityCheck(accountId))
        .catch(console.error);
    },
    identityConfirmationAttempts(accountId) {
      Vue.delete(this.identityCheck, accountId);

      axios.post('v1/onboarding/members/actions/identity-check-attempt', { account_id: accountId })
        .then(() => this.requestAccountIdentityCheck(accountId))
        .catch(console.error);
    },
    getUserFirstLastName(userId) {
      const user = this.users[userId];
      return user
        ? `${user.firstname} ${user.lastname}`
        : 'Unknown';
    },
    async download() {
      this.downloadInProgress = true;
      try {
        const queryParams = new URLSearchParams(this.appliedFilters).toString();
        const { data } = await axios.get(`v1/onboarding/enrollments/export?${queryParams}`);

        const fileName = `export_members_${moment().utc().format('YYYYMMDD_HHmmss')}.csv`;
        this.downloadFile(data, fileName);
      } catch (err) {
        this.$noty.error(
          err?.response?.data?.message ?? 'Error downloading'
        );
      } finally {
        this.downloadInProgress = false;
      }
    },
    downloadFile(content, fileName) {
      const fileURL = URL.createObjectURL(new Blob([content]), { type: 'octet/stream' });
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName);
      document.body.appendChild(fileLink);

      fileLink.click();
      URL.revokeObjectURL(fileLink.href);
      document.body.removeChild(fileLink);
    },
    async impersonate() {
      try {
        this.impersonationInProgress = true;
        if (!this.selectedItem.account_uuid) {
          this.$noty.error('Member must create an account first');
          return;
        }

        const configs = this.environmentConfigs ?? {};

        let onboardingURL;
        switch (this.selectedClient.configuration?.d2c) {
          case D2C_OPTIONS.BLOSSOM:
            onboardingURL = configs.onboarding_blossom_url.replace(/\/$/, '');
            break;
          case D2C_OPTIONS.BECOME:
            onboardingURL = configs.onboarding_become_url.replace(/\/$/, '');
            break;
          default:
            onboardingURL = configs.onboarding_url.replace(/\/$/, '');
            break;
        }

        if (!onboardingURL) {
          this.$noty.error('Unable to find a valid onboarding URL');
          return;
        }

        const { data } = await axios.post('v1/onboarding/members/impersonation/generate', { account_id: this.selectedItem.account_uuid });
        window.open(`${onboardingURL}/impersonate/${data.token}`, '_blank');
      } catch (err) {
        console.error('Failed to impersonate: ', err);
        this.$noty.error('An unknown error ocurred, please try again');
      } finally {
        this.impersonationInProgress = false;
      }
    },
    async sendPasswordResetEmail() {
      if (this.passwordResetInProgress) {
        return;
      }

      try {
        this.passwordResetInProgress = true;
        const { account_uuid } = this.selectedItem || {};
        if (!account_uuid) {
          this.$noty.error('Member must create an account first');
          return;
        }

        await axios.post('v1/onboarding/members/password-recover', { account_id: account_uuid });
        this.$set(this.passwordResetsDone, account_uuid, true);
        this.$noty.success('Password recover email sent', { timeout: 750, progressBar: false });
      } catch (err) {
        console.error('Failed to trigger password reset email: ', err);
        this.$noty.error('An unknown error ocurred, please try again');
      } finally {
        this.passwordResetInProgress = false;
      }
    },
  },
};
</script>

<style>
.member-list {
  font-size: 15px;
}
small.really-small {
  font-size: 10px;
}
.truncate {
  max-width: 275px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 2px;
}
</style>
